import React, { Suspense } from 'react';

interface LoadableProps {
	loading: React.ReactNode
	loader: () => Promise<{ default: React.ComponentType<any> }>
}

export default function Loadable ({
	loading,
	loader,
}: LoadableProps) {

	const Component = React.lazy(loader);

	return (props: any) => {
		
		return (
			<Suspense fallback={loading}>
				<Component {...props} />
			</Suspense>
		);
	};
}
