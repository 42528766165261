import React from 'react';
import { createRoot } from 'react-dom/client';

import Loadable from '@/components/Loadable';
import Loader from '@/components/Loader';

const nodes = document.querySelectorAll('.mozart-bed-color-picker');

if (nodes.length > 0) {

	const BedColorPicker = Loadable({
		loader: () => import('./index'),
		loading: <Loader />
	});

	const { BedColorPickerImages } = window;

	nodes.forEach((node) => {
		const root = createRoot(node);
		root.render(
			<BedColorPicker data={BedColorPickerImages} />
		);
	});
}
