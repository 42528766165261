import React from 'react';
import {createRoot} from 'react-dom/client';

import Loadable from "@/components/Loadable";
import Loader from '@/components/Loader';

const nodes = document.querySelectorAll('.mozart-fabric-sale-box');

if (nodes.length > 0) {

    const FabricSampleSaleBox = Loadable({
        loader: () => import('./index'),
        loading: <Loader />
    });

    nodes.forEach(node => {
        const root = createRoot(node);
        root.render(<FabricSampleSaleBox/>);
    });
}
