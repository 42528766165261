import React from 'react';
import { createRoot } from 'react-dom/client';

import Loadable from '@/components/Loadable';
import Loader from '@/components/Loader';

const node = document.getElementById('mozart-sample-box-picker');

if (node) {

	const SampleBoxPicker = Loadable({
		loader: () => import('./index'),
		loading: <Loader cover transparent />
	});

	const root = createRoot(node);
	root.render(<SampleBoxPicker />);
}
