import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

let node = document.createElement('div');
node.className = 'mozart-save-user-init-form';
document.body.appendChild(node);

const nodes = document.querySelectorAll('.mozart-save-user-init-form');

if (nodes.length > 0) {

    const SaveUserForm = lazy(() => import('./index'));

    createRoot(node).render(<SaveUserForm />);
}
