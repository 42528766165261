import React from 'react';
import { createRoot } from 'react-dom/client';

import Loader from '@/components/Loader';
import Loadable from '@/components/Loadable';

const pageNode = document.getElementById('mozart-bed-configurator');

if (pageNode) {

	const BedConfigurator = Loadable({
		loading: <Loader cover transparent />,
		loader: () => import('./index'),
	});

	const root = createRoot(pageNode);
	root.render(<BedConfigurator />);
}
