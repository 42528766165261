import React from 'react';
import {createRoot} from 'react-dom/client';

import Loadable from "@/components/Loadable";
import Loader from '@/components/Loader';

const nodes = document.querySelectorAll('.mozart-sale-box');

if (nodes.length > 0) {
    const SaleBox = Loadable({
        loader: () => import('./index'),
        loading: Loader,
    });

    nodes.forEach(node => {
        const root = createRoot(node);
        root.render(<SaleBox/>);
    });
}
