import React from 'react';
import { createRoot } from 'react-dom/client';

import Loadable from '@/components/Loadable';
import Loader from '@/components/Loader';

const nodes = document.querySelectorAll('.mozart-bed-images-gallery-new');

if (nodes.length > 0) {

	const BedImagesGallery = Loadable({
		loader: () => import('./index'),
		loading: <Loader />
	});

	const { mozartBedImagesGalleriesNew } = window;

	nodes.forEach((node) => {
		const root = createRoot(node);
		root.render(
			<BedImagesGallery images={mozartBedImagesGalleriesNew[node.dataset.index]} />
		);
	});
}
