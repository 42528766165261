import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

const node = document.getElementById('mozart-zip-questionnaire');

if (node) {

	const ZipQuestionnaire = lazy(() => import('./index'));

	createRoot(node)
		.render(<ZipQuestionnaire />);
}
