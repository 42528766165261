import { createRoot } from 'react-dom/client';

import Loadable from '@/components/Loadable';
import Loader from '@/components/Loader';

const node = document.getElementById('mozart-accessories-buy-box');

if (node) {

	const BuyBox = Loadable({
		loader: () => import('./index'),
		loading: <Loader />,
	})

	createRoot(node)
		.render(<BuyBox />);
}
