import { lazy } from 'react';
import { createRoot } from 'react-dom/client';

let node = document.createElement('div');
node.id = 'mozart-firmness-quiz';
document.body.appendChild(node);

const links = document.querySelectorAll('a[href*="#deep-sleep-formel-popup"]');

if (links.length > 0) {

	const MozartFirmnessQuiz = lazy(() => import('./index'));

	createRoot(node)
		.render(<MozartFirmnessQuiz />);
}
