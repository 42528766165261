import classNames from 'classnames';

import styles from './index.module.scss';

interface LoaderProps {
  className?: string
  cover?: boolean
  fullCover?: boolean
  transparent?: boolean
}

export default function Loader ({
  className,
  cover,
  fullCover,
  transparent
}: LoaderProps) {

  const loaderClasses = classNames(styles['loader'], className, {
    [styles['loader--cover']]: cover || fullCover,
    [styles['loader--full-cover']]: fullCover,
    [styles['loader--transparent']]: transparent,
  });

  return (
    <div className={loaderClasses}>
      <div className={styles['sk-fading-circle']}>
        <div className={`${styles['sk-circle1']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle2']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle3']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle4']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle5']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle6']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle7']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle8']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle9']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle10']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle11']} ${styles['sk-circle']}`}/>
        <div className={`${styles['sk-circle12']} ${styles['sk-circle']}`}/>
      </div>
    </div>
  );

};
